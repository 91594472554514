<template>
  <div class="js-common-wrap js-arworkbench-wrap">
    <div class="js-common-head">
      <label>资源包</label>
      <div>
        <el-button type="primary" size="small" @click="handleAdd">高级搜索</el-button>
        <el-button type="primary" size="small" @click="handleAdd">导入</el-button>
        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column label="预览">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.url" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="creater" label="创建人"></el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{scope.row.createtime | timesToDatetime}}
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="备注"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state" type="primary" size="small">启用</el-button>
            <el-button v-else type="danger" size="small">未启用</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <i class="el-icon-edit-outline" @click.stop="handleEdit"></i>
          <i class="el-icon-delete" @click.stop="handleDelete"></i>
          <i class="el-icon-download" @click.stop="handleDownload"></i>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination></common-pagination>
    <standar-add v-model="addDialogVisible"></standar-add>
    <standar-edit v-model="editDialogVisible"></standar-edit>
    <common-delete v-model="deleteDialogVisible"></common-delete>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import StandarAdd from '@/components/StandarAdd'
import StandarEdit from '@/components/StandarEdit'
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components: {
    CommonPagination,
    StandarAdd,
    StandarEdit,
    CommonDelete
  },
  data() {
    return {
      tableData: [{
        id: 10001,
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        type: '人脸识别',
        name: '资源包名称',
        creater: '张三',
        createtime: '1644814708285',
        desc: '备注信息',
        state: 0
      },{
        id: 10001,
        url: 'https://ariseprod.llvision.com:45556/file/20220218160008090c540c2c7.jpg?bucketName=arise-msp-prod&storageDir=upload/car&storageFlag=2&token=58a39bbc651144de230bad25523a0834',
        type: '人脸识别',
        name: '资源包名称',
        creater: '张三',
        createtime: '1644814708285',
        desc: '备注信息',
        state: 1
      }],
      addDialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false
    }
  },
  mounted() {
  },
  methods: {
    handleAdd(){
      console.log('搜索…………………………')
      this.addDialogVisible = true
    },
    handleRowClick(row, column, event){
      console.log(row)
      // this.$router.push({
      //   path: `/remoteCollaboration/historicalRecords/details/${parseInt(Math.random() * 190)}`,
      // })
    },
    handleEdit(){
      console.log('操作…………………………')
      this.editDialogVisible = true
    },
    handleDelete(){
      this.deleteDialogVisible = true
    },
    handleDownload(){

    }
  },
}
</script>

<style lang="scss" scoped>
.js-arworkbench-wrap{
  .js-common-content {
    .js-table {
      .img{
        width: 120px;
        height: 90px;
      }
    }
  }
}
</style>
